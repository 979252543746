<template lang="html">
<v-container fluid>

  <v-alert
  prominent
  type="info"
  v-if="responseData.sudah_ulas == 0"
  >Belum mengulas pegawai. Jumlah pegawai yang harus diulas adalah {{responseData.harus_ulas}} orang</v-alert>
  <v-alert
  prominent
  type="success"
  v-else-if="responseData.sudah_ulas == responseData.harus_ulas && responseData.harus_ulas > 0"
  >Sudah mengulas pegawai. Total ulasan berjumlah {{responseData.sudah_ulas}}</v-alert>
  <v-alert
  prominent
  type="info"
  v-else-if="responseData.sudah_ulas > 0"
  >Sudah melakukan {{responseData.sudah_ulas}} ulasan kompetensi dari {{responseData.harus_ulas}} orang</v-alert>

  <v-row v-if="!pegawai.length">
    <v-col v-for="i in [1,2,3,,4,5]" :key="i">
      <v-skeleton-loader
      class="mx-auto"
      min-width="300"
      type="card"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
  <v-row class="mt-5">
    <v-col v-for="(peg,i) in pegawai" :key="i" align="center">
      <base-material-card
        class="v-card-profile"
        :avatar="peg.foto"
        style="width:300px"
      >
        <v-card-text class="text-center" height="100px">
          <h4 class="display-2 mb-1 orange--text text--darken-2" style="height:55px;">
            {{peg.nama}}
          </h4>
          <h4 class="font-weight-light grey--text mb-2">
            NIP. {{peg.nip}}
          </h4>
          <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
            {{peg.jabatan}}
          </h4>
          <div class="mt-5">
            <v-chip v-if="peg.status" small label :class="reviewStatus(peg)" dark class="ma-1">{{peg.status}}</v-chip>
            <v-chip small label class="ma-1 orange darken-2" dark>{{peg.jumlah}}/{{peg.harus}} Pertanyaan</v-chip>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
          color="primary"
          rounded
          class="mr-0"
          @click="openDialog(peg.nip,i,peg)"
          :loading="loadingEl == i"
          >
            <span v-if="peg.remedial"><v-icon>mdi-pencil-box</v-icon> Review Ulang</span>
            <span v-else-if="peg.submit_kuesioner && (peg.jumlah < peg.harus)"><v-icon left>mdi-pencil-box</v-icon> Review Belum Lengkap</span>
            <span v-else-if="peg.submit_kuesioner"><v-icon>mdi-eye</v-icon> Lihat</span>
            <span v-else><v-icon>mdi-pencil-box</v-icon> Ulas</span>
          </v-btn>
        </v-card-actions>
      </base-material-card>
    </v-col>
  </v-row>

  <v-dialog v-model="dialog">
    <KuesionerKompetensiDetail :dataPegawai="dataPegawai" @onClose="(x)=>{dialog=x}"/>
  </v-dialog>

  <v-dialog v-model="dialogInformasiReviewKompetensi" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Informasi Review Kompetensi</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="formDialogInformasiReviewKompetensi" style="font-size:18px">
          <ol>
            <li>Review Kompetensi adalah proses penyampaian informasi yang berkaitan dengan perilaku yang ditampilkan sehari-hari oleh atasan/rekan sejawat/bawahan yang berinteraksi sehari-hari di dalam pekerjaan atau memiliki hubungan kerja dengan anda sebagai reviewer.</li>
            <br>
            <li>Review Kompetensi <strong>tidak mempengaruhi besaran TKD</strong> yang akan diterima seorang reviewer (anda) atau reviewer (orang yang anda review kompetensi nya).</li>
            <br>
            <li>Reviewer kompetensi dilakukan secara acak oleh sistem dan hasil review yang masuk menjadi anonim.</li>
            <br>
            <li>Hasil review kompetensi akan digunakan sebagai :</li>
            <ol style="list-style-type: lower-alpha;">
              <li>Salah satu data rujukan untuk pengembangan kompetensi reviewer;</li>
              <li>Menjadi feedback untuk reviewer mengembangkan kapasitas diri, pribadi, dan kualitas layanan yang dilakukan.</li>
            </ol>
            <br>
            <li>Akan ada pernyataan yang membutuhkan evidence/keterangan yang menjelaskan bahwa perilaku tersebut sesuai dengan penilaian yang dilakukan, jelaskan/tuliskan perilaku yang pernah ditunjukkan oleh reviewer yang pernah terlihat/teramati/terjadi.</li>
            <br>
            <li>Pilihlah pernyataan perilaku yang benar-benar sesuai dengan apa yang ditunjukan oleh reviewer, sehingga kebijakan yang diambil dari proses review kompetensi ini dapat sesuai dengan kebutuhan.</li>
          </ol>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-checkbox class="mx-5" v-model="form.check" @change="setCheck()" label="Setujui Informasi"></v-checkbox>
        <v-btn id="btn-simpan" color="success" :disabled="form.check == null || form.check == false ? false : disabledSimpan" @click="setujuiInformasiReviewKompetensi()">Simpan dan Lanjutkan</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import KuesionerKompetensiDetail from './KuesionerKompetensiDetail'
import KuesionerService from '@/services/KuesionerService'
import store from '@/store'

export default {
  components:{
    KuesionerKompetensiDetail
  },
  data:function(){
    return{
      responseData:{},
      pegawai:[],
      dialog: false,
      dataPegawai:{},
      loadingEl:-1,
      dialogInformasiReviewKompetensi: false,
      form:{},
      disabledSimpan: false
    }
  },
  created(){
    var kode_skpd = this.$store.getters["user/data"].data_asn["kolok"]
    var skpds = ["4.03.01", "2.10.01", "4.01.01", "4.02.01", "4.05.02", "1.01.01", "1.02.01","4.05.07","4.05.08","4.05.09","4.05.10","4.05.11","4.05.12","4.05.13","4.05.14","4.05.15","4.05.16","4.05.17","4.05.18","4.05.19","4.05.20","4.05.21","4.05.22","4.05.23","4.05.24","4.05.25","4.05.26","4.05.27","4.05.28","4.05.29","4.05.30","4.05.31","4.05.32","4.05.33","4.05.34","4.05.35","4.05.36"];
    var base_url = window.location.origin
    // var check_skpd = this.$store.getters["user/data"].data_asn["perangkat daerah"]
    // if(check_skpd == 'Badan Kepegawaian, Pendidikan dan Pelatihan' || check_skpd == 'Badan Kepegawaian dan Pengembangan Sumber Daya Manusia'
    // || check_skpd == 'Badan Perencanaan Pembangunan, Penelitian dan Pengembangan' || check_skpd == 'Badan Keuangan dan Aset Daerah'
    // || check_skpd == 'Dinas Komunikasi dan Informatika' || check_skpd == 'Sekretariat Daerah' || check_skpd == 'Badan Pengelolaan Keuangan dan Aset'){
    if(skpds.includes(kode_skpd)){
      this.getData()
    }else{
      store.commit('snackbar/setSnack',{
            message:'Maaf, anda tidak bisa mengakses halaman Review Kompetensi. Untuk sementara hanya OPD BKPSDM, Diskominfo, BKAD, Bappelitbang, Sekda, Disdik, dan Dinkes dahulu. Terima kasih.',
            color:'warning'})
      let confirmAction = confirm("Maaf, anda tidak bisa mengakses halaman Review Kompetensi. Untuk sementara hanya OPD BKPSDM, Diskominfo, BKAD, Bappelitbang, Sekda, Disdik, dan Dinkes dahulu. Terima kasih.");
      if (confirmAction) {
        window.location.href = base_url + '/#/dashboard'
      } else {
        window.location.href = base_url + '/#/dashboard'
      }
    }
  },
  watch:{
    dialog(val){
      val || this.getData()
    }
  },
  methods:{
    setCheck(){
      if(this.form.check == true)this.disabledSimpan = false
      else this.disabledSimpan = true
    },
    getData(){
      this.form.check = false
      KuesionerService.kuesionerGetData().then(response=>{
        this.pegawai = response.data.data
        for (let i = 0; i < this.pegawai.length; i++) {
          const element = this.pegawai[i];
          if(element.check){
            this.form.check = true
            break
          }
        }
        if(!this.form.check){
          this.dialogInformasiReviewKompetensi = true
          this.disabledSimpan = false
        }
        this.responseData = response.data
      })
    },

    reviewStatus(peg){
      let status = peg.status ? peg.status.toLowerCase() : ''
      if(peg.submit_kuesioner && (peg.jumlah < peg.harus)){
          status = 'Review belum lengkap'
          peg.status = 'Review belum lengkap'
      }

      return{
        red:status === 'belum mengulas',
        blue:status === 'on going',
        green: status === 'sudah diulas',
        brown: status === 'Review belum lengkap'
        // green:status == 'belum direview'
      }
    },

    openDialog(nip, idx, peg){
      this.loadingEl = idx
      KuesionerService.kuesionerUlasNIP(nip).then(response => {
        console.log(response.data)
        this.dataPegawai = []
        this.dataPegawai = response.data
        this.dataPegawai.jumlah = peg.jumlah
        this.dataPegawai.harus = peg.harus
        this.dialog=true
      }).finally(() => {
        this.loadingEl = -1
      })
    },

    setujuiInformasiReviewKompetensi(){
      let check = this.form.check
      if(check == false){
        store.commit('snackbar/setSnack',{
            message:'Silahkan setujui dahulu informasi review kompetensi untuk melanjutkan isi review kompetensi. Terima kasih!',
            color:'warning'})
      }else{
        this.disabledSimpan = true
        let item = document.getElementById("btn-simpan");
        item.textContent = 'Silahkan tunggu'
        KuesionerService.kuesionerSimpanPersetujuanReviewKompetensi(check)
                                                  .then(response=>{
                                                    store.commit('snackbar/setSnack',{message:response.data.message,color:response.data.response.toLowerCase()})
                                                    this.dialogInformasiReviewKompetensi = false
                                                    this.disabledSimpan = false
                                                    item.textContent = 'Simpan'
                                                  })
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
